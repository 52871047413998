const INIT_STATE = {
    checkout_question:[{ questions: "", isRequired: false, }],
    product_theme:"darkmode",
    accent_theme:null,
    buy_button_text:"Buy now",
}
const productCheckoutReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_CHECKOUT':
            return action.payload
        case 'RESET_PRODUCT_CHECKOUT':
            return INIT_STATE
        default:
            return state
    }
}
export default productCheckoutReducer