const INIT_STATE = {
    tiers:[{
        tier_name:"",
        price:0,
        type:"monthly"
    }]
}
const productTierReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_TIER':
            return action.payload
        case 'RESET_PRODUCT_TIER':
            return INIT_STATE
        default:
            return state
    }
}
export default productTierReducer