

import { Modal } from "react-bootstrap"
import linkIcon from "../../../../assets/img/modals/link-icon.svg"
import ModalErrors from "../../../errors/modal-errors"
import { useState } from "react"
import { useEffect } from "react"

import { hideLoader, showLoader } from "../../../loader"
import { apiUrl } from "../../../../utils/url"
import { audiencePosts as getLinkImages } from "../../../../services/postRequests/audience"
import { useDispatch, useSelector } from "react-redux"
import EditLinkProfilePicUploadModal from "../../../picture-upload-modal"
import CroppieModal from "../../../croppie-modal"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import embed_block_banner from "../../../../assets/img/banner-modal-icon.svg"
import imageIcon from "../../../../assets/img/image-icon.svg";

let croppie_image = { url: "", id: null };
const UploadImageBanner =
    ({ show, slug, basePath, handleHide }) => {
        const history = useHistory()
        const [images, setImages] = useState([])
        const editLinkModal = useSelector(state => state.editLinkModal)
        const showprofilePicModal = useSelector(state => state.profilePicUploadReducer);
        const [croppie, setCroppie] = useState(null);
        const customizeThemeReducer = useSelector(state => state.customizeThemeReducer);
        const dispatch = useDispatch()
        const [showCroppieModal, setshowCroppieModal] = useState(false);
        const profilePicUploadReducer = useSelector(state => state.profilePicUploadReducer);
        const { bannerModal } = useSelector(state => state);
        let [bannersData, setbannersData] = useState({ editLink: "", selected: null })

        useEffect(() => {
            if (slug) {
                if (bannerModal?.from) {
                    let pathname = bannerModal?.from == "banners" ? bannerModal?.from : "link-image"
                    showLoader();
                    const api = apiUrl + `/project/${pathname}/get/` + slug;
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
                    const setOptions = {
                        method: "GET",
                        headers: myHeaders,
                    }
                    getLinkImages(null, api, hideLoader, setOptions).then((response) => {
                        if (bannerModal?.from !== "banners") setImages(response)
                        else setImages(response)
                    }).catch(() => hideLoader())
                }
            }
        }, []);

        $(document).on('click', '.banner-div', function () {
            $(document).find(".banner-div").removeClass('active');
            $(this).toggleClass('active');

            if (bannerModal?.from !== "banners")
                dispatch({
                    type: "SET_EDIT_LINK_MODAL", payload: {
                        ...editLinkModal,
                        image_url: $(this).children().attr("src"),
                        link_image: $(this).attr("id")
                    }
                })
            else {
                setbannersData({ editLink: $(this).children().attr("src"), selected: $(this).attr("id") })
            }

        });
        const bannerUpload = () => {
            showLoader()
            $("#img_logo").click()
            hideLoader()
        }

        const handleSubmit = (e) => {
            e.preventDefault();
            if (bannerModal?.from !== "banners") {
                dispatch({
                    type: "SET_EDIT_LINK_MODAL", payload: {
                        ...editLinkModal,
                        show: true,
                    }
                })
                dispatch({ type: "RESET_BANNER_MODAL" })
            } else {
                dispatch({
                    type: "SET_CUSTOMIZE_THEME", payload: {
                        ...customizeThemeReducer,
                        ...bannersData,
                        profile_style: "banner",
                    }
                })
                dispatch({ type: "RESET_BANNER_MODAL" })
            }
        }

        const dropzoneChange = (e) => {
            showLoader()
            setTimeout(() => {
                setTimeout(() => {
                    dispatch({ type: "RESET_EDITLINK_PROFILE_PIC_MODAL" });
                    dispatch({ type: "RESET_PROFILE_PIC_MODAL" });
                }, 400);
                generate_image_preview(e.target);
                hideLoader();
            }, [500])
        }

        function generate_image_preview(input) {

            showLoader();
            if (input.files && input.files[0]) {
                let reader = new FileReader();
                //   alert(input.files[0].size);
                if (input.files[0].size >= 5242880) {
                    handleHideCropie()
                    toast.error("File Size Should not exceed 5MBs")
                    return;
                }
                var val = input.files[0].name;
                var valid_extensions = ['png', 'jpg', 'jpeg'];
                var extension = val.substring(val.lastIndexOf('.') + 1, val.length) || val;
                if (!valid_extensions.includes(extension)) {
                    toast.error('Invalid Image File. Should be png, jpg or jpeg');
                    return;
                }
                
                setshowCroppieModal(true)
                reader.onload = event => {
                    croppie_image = {
                        url: event.target.result,
                    };
                    const el = document.getElementById("image_demo2");
                    try {
                        if (el) {
                            var croppieInstance = new Croppie(el, {
                                enableExif: true,
                                viewport: {
                                    width: 320,
                                    height: 208,
                                    type: 'square'
                                },
                                boundary: {
                                    width: 350,
                                    height: 350
                                }
                            });
                            croppieInstance.bind({
                                url: event.target.result,
                            }).then(function () {
                                
// console.log('jQuery bind complete');
                                setTimeout(() => {

                                }, 300);
                            }).catch(() => {
                                croppieClose()
                            })
                            setCroppie(croppieInstance)
                        }
                    } catch { }

                };
                reader.readAsDataURL(input.files[0]);
            }
        };

        const handleHideCropie = () => {
            $(".dropzone").val(null)
            setshowCroppieModal(false);
            setCroppie(null)
            croppie_image = { url: "", id: null };
            dispatch({ type: "SET_BANNER_MODAL", payload: { ...bannerModal, show: true } })
        };
        const croppieClose = () => {
            showLoader()
            setshowCroppieModal(false);
            setCroppie(null)
            croppie_image = { url: "", id: null };
            setTimeout(() => {

                dispatch({ type: "SET_BANNER_MODAL", payload: { ...bannerModal, show: true } })
                hideLoader();
            }, 400);
        };
        const croppedImage = () => {
            if (croppie !== null) {
                showLoader();
                // return;
                croppie.result({
                    size: "original",
                    format: "png",
                    quality: 1,
                    type: "blob"
                })
                    .then((response) => {
                        if (bannerModal?.from) {
                            const pathname = bannerModal?.from == "banners" ? bannerModal?.from : "link-image"
                            const api = apiUrl + `/project/${pathname}/create`;
                            var myHeaders = new Headers();
                            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
                            const form_data = new FormData();

                            form_data.append("project", slug)
                            form_data.append(pathname == "banners" ? "banner" : "image", response)
                            const setOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: form_data
                            }
                            getLinkImages(api, showLoader, setOptions).then((response) => {
                                setshowCroppieModal(false)
                                if (response) {
                                    if (bannerModal?.from !== "banners") {
                                        dispatch({
                                            type: "SET_EDIT_LINK_MODAL", payload: {
                                                ...editLinkModal,
                                                image_url: response?.link_image?.image,
                                                link_image: response?.link_image?.id,
                                                show: true,
                                            }
                                        })
                                    }
                                    else {

                                        dispatch({
                                            type: "SET_CUSTOMIZE_THEME", payload: {
                                                ...customizeThemeReducer,
                                                editLink: response?.banner?.thumbnail, selected: response?.banner?.id,
                                            }
                                        })
                                        // dispatch({ type: "RESET_BANNER_MODAL" })

                                    }
                                } else croppieClose()
                                dispatch({ type: "RESET_BANNER_MODAL" })
                                hideLoader()

                            }).catch(() => {
                                croppieClose()
                            })
                        }

                    })
            }
        };
        const handleClose = () => {

            if (bannerModal?.from == "editor-link")
                handleHide()
            else
                history.push(basePath + "/page-editor/customizePageModal/customizePageTheme")
            dispatch({ type: "RESET_BANNER_MODAL" })
        }
        return (
            <>
                <Modal centered show={bannerModal?.show} onHide={handleClose} id="uploadImageBanner" size="lg" >
                    <div class="modal-content">

                        <div class="modal-body">
                            <div className="form-group">
                                <img src={embed_block_banner} width="30px" alt="" />
                                <h4 className="mt-2 mb-1">{bannerModal?.from == "editor-link" ? "Add Link Image" : " Add Profile Banner"}</h4>
                                <p className="text-gray font-weight-500">Upload a new image or select an existing images</p>
                            </div>


                            <section class="mt-2 mb-2">
                                <form action="" method="POST" enctype="multipart/form-data" onSubmit={handleSubmit}>
                                    <div class="">
                                        <div class="row m-0">
                                            <div class="col-12 pl-0 pr-0">
                                                <div class="form-group mb-0">
                                                    <div className="dropzone-wrapper dropzone-wrapper4 product-dropzone mb-2" onClick={bannerUpload} style={{ background: "transparent", border: "1px dashed #A0A0A0", height: 100 }}>
                                                        <div className="dropzone-desc" style={{top: "15%"}}>
                                                            <img src={imageIcon} alt="" />
                                                            <h6 className="mt-2 mb-1 text-gray">Drag and drop files, or <a className="text-primary decoration-none">click here to browse</a></h6>
                                                            <small className="text-muted-light">5MB max file size upload</small>
                                                        </div>
                                                        <input type="file" accept=".png,.jpg,.jpeg" name="cover_image" className="dropzone" onChange={dropzoneChange} />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row m-0">
                                            <div className="col-12 pl-0 pr-0 banners-main">
                                                {(images && images.length > 0 && images?.map((value) => {
                                                    if (value?.image) {
                                                        return (
                                                            <div className={`banner-div ${editLinkModal?.image_url == value?.image ? "active" : null}`} key={value?.id} id={value?.id}>
                                                                <img src={value?.image} alt={"thumbnail"} />
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div className={`banner-div ${editLinkModal?.image_url == value?.thumbnail ? "active" : null}`} key={value?.id} id={value?.id}>
                                                                <img src={value?.thumbnail} alt={"thumbnail"} />
                                                            </div>
                                                        )
                                                    }
                                                }))}
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div className="col-12 pl-0 pr-0">
                                                <div class="text-center mt-4 d-flex">
                                                    <button type="submit" data-dismiss="modal" class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center">
                                                        <b class="me-2">Cancel</b>
                                                        <small class="badge bg-light text-dark p-1">Esc</small>
                                                    </button>
                                                    <button type="submit" className="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                                        <b>Update</b>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </section>

                        </div>

                    </div>
                </Modal>
                <CroppieModal show={showCroppieModal} handleHide={handleHideCropie} croppieClose={croppieClose} croppedImage={croppedImage} to={profilePicUploadReducer?.to} from={bannerModal?.from} />
            </>
        )
    }
export default UploadImageBanner