import repeatBlackIcon from "../../assets/img/modals/repeat-black.svg";
import repeatIcon from "../../assets/img/modals/repeat.svg";
import integration_google_analytics from "../../assets/img/modals/integration-google-analytics.svg";
import integration_facebook_analytics from "../../assets/img/modals/integration-facebook-pixel.svg";
import integration_mailchimp from "../../assets/img/modals/integration-mailchimp.svg";
import integration_convertkit from "../../assets/img/modals/integration-convertkit.svg";
import integration_sendinblue from "../../assets/img/modals/integration-sendinblue.svg";
import integration_meet from "../../assets/img/modals/integration-meet.svg";
import zoomIcon from "../../assets/img/modals/zoom-icon.svg";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { hideLoader, showLoader } from "../loader";
import returnIcon from "../../assets/img/modals/return.svg";
import { useDispatch, useSelector } from "react-redux";
import ModalErrors from "../errors/modal-errors";
import { toast } from "react-toastify"
import { apiUrl } from "../../utils/url";
import { FacebookPixelModal, GoogleAnalyticsModal, ConvertkitModal, MailchimpModal, SendinblueModal, ConnectZapierModal } from "./integrations-modals";
import ConnectCalendarModal from "../connect-calendar-modal";
import getProducts from "../../services/getProducts";
import Teammoadal from "../teammember/team_modal";
import getProjects from "../../services/getProjects";
import { useAlert } from "../../contexts/alertContext";

const IntegrationsPage = ({ setShowCustomizeParent }) => {
    const dispatch = useDispatch()
    const [show, setshow] = useState(false);
    const location = useLocation()
    const history = useHistory()
    const { subPath } = useParams();
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const isPro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const customizePageReducer = useSelector(state => state?.customizePageReducer);
    const [integrationGoogleReducer,setIntegrationGoogle] = useState({analytics_id:null,tab:"integrations",disconnect:0});
    const [integrationFacebookReducer,setIntegrationFacebook] = useState({pixel_id:null,tab:"integrations",disconnect:0});
    const [integrationMailChimpReducer,setIntegrationMailchimp] = useState({api_key: null,server_prefix: null,list_id: null,tab:"integrations",disconnect:0});
    const [integrationConvertkitReducer,setIntegrationConvertKit] = useState({api_key: null,form_id: null,tab:"integrations",disconnect:0});
    const [integrationSendinblueReducer,setIntegrationSendinBlue] = useState({api_key: null,list_id: null,tab:"integrations",disconnect:0});
    const [integrationEmailReducer,setIntegrationEmail] = useState({emailSignupUrl: null,tab:"integrations"});
    const [integrationContactReducer,setIntegrationContact] = useState({contactFormUrl: null,tab:"integrations"});
    const [integrationProductReducer,setIntegrationProduct] = useState({productSaleUrl: null,tab:"integrations"});
    const meeting_tokens = useSelector(state => state?.meetConnectedReducer);
    const [connectModal,setConnectModal] = useState(null);
    const [unlinkModal, setUnlinkModal] = useState({show:false,type:null});
    const encryptedProjectSlug = slug ?  window.btoa(slug): null;
    const [error, setError] = useState([]);
    const [allProducts, setallProducts] = useState([]);
    const [showModalasd, setShowModalasd] = useState(false)
    const planType = useSelector(state => state?.plantypeReducer);
    const integrationzapierReducer = useSelector(state => state?.integrationZapierReducer);
    const { setAlert } = useAlert();
    useEffect(() => {
        if (!isPro) {
            setShowModalasd(true);
        }else {
            setShowModalasd(false);
            // callAllProducts();
            // getIntegrations()

        }
    }, [isPro,planType]);
    const getIntegrations = () => {
        getProjects(slug).then((response) => {
             dispatch({ type: "SET_MEET_CONNECTED", payload: {...meeting_tokens, meet_token: response?.meet_token ? true:false } });
             window.opener?.postMessage({ type: 'meetToken', meetToken:response?.meet_token }, '*');
                let temp_obj = {};
                response?.settings?.map((val) => temp_obj = { ...temp_obj, [val?.key]: val?.value, project_id: val?.project_id });
                setIntegrationGoogle(temp_obj?.google_analytics?.length > 0 ? {
                    analytics_id: temp_obj?.google_analytics,
                    disconnect: 1
                } : { analytics_id: temp_obj?.google_analytics, })
                setIntegrationFacebook(temp_obj?.facebook_pixel?.length > 0 ? {
                    pixel_id: temp_obj?.facebook_pixel,
                    disconnect: 1
                } : { pixel_id: temp_obj?.facebook_pixel, })
                if (temp_obj?.mailchimp_settings)
                    setIntegrationMailchimp({
                        ...JSON?.parse(temp_obj?.mailchimp_settings),
                        disconnect: 1
                    })
                if (temp_obj?.convertkit_settings)
                    setIntegrationConvertKit({
                        ...JSON?.parse(temp_obj?.convertkit_settings),
                        disconnect: 1
                    })
                if (temp_obj?.sendinblue_settings)
                    setIntegrationSendinBlue({
                        ...JSON?.parse(temp_obj?.sendinblue_settings),
                        disconnect: 1
                    })
                if (temp_obj?.emailSignupUrl) {
                    setIntegrationEmail({ emailSignupUrl: temp_obj?.emailSignupUrl });
                }
                if (temp_obj?.contactFormUrl) {
                    setIntegrationContact({ contactFormUrl: temp_obj?.contactFormUrl });
                }
                if (temp_obj?.productSaleUrl) {
                    setIntegrationProduct({ productSaleUrl: temp_obj?.productSaleUrl });
                }
        })
    }
        const callAllProducts = () => {
            getProducts(slug).then((response) => {
                setallProducts(response);
            })
        }
    // const meetConnected = allProducts?.data?.some(product => product?.calendar[0]?.meeting_location === 'google');
    const setParams = (tabName) => {
        showLoader()
        if (!isPro) {
            setShowModalasd(true);
            return;
        }
        setTimeout(() => {
            hideLoader()
            setConnectModal(tabName);
        }, 300);
    }

    const diconnecting = (name, values, actionType) => {
        showLoader();
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const errorList = []
        const formdata = new FormData();
        const formValues = { project: slug, tab: "integrations", integration: name, disconnect: 1 };

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + `/project/settings/update`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {

                    let temp_obj = { ...values }
                    for (const key in temp_obj) {
                        if (Object.hasOwnProperty.call(temp_obj, key)) {
                            delete temp_obj["disconnect"];
                        }
                        dispatch({ type: actionType, payload: { ...temp_obj } })
                        if (name == "mailchimp") {
                            setIntegrationMailchimp({...integrationMailChimpReducer,"disconnect":0})
                        }
                        else if (name == "convertkit") {
                            setIntegrationConvertKit({...integrationConvertkitReducer,"disconnect":0})
                        }
                        else if (name == "facebook") {
                            setIntegrationFacebook({...integrationFacebookReducer,"disconnect":0})
                        }
                        else if (name == "google") {
                            setIntegrationGoogle({...integrationGoogleReducer,"disconnect":0})
                        }
                        else if (name == "sendinblue") {
                            setIntegrationSendinBlue({...integrationSendinblueReducer,"disconnect":0})
                        }
                    }
                    if (type == "emailSignupUrl") {
                        setIntegrationEmail({ emailSignupUrl: null });
                    }
                    if (type == "contactFormUrl") {
                        setIntegrationContact({ contactFormUrl: null });
                    }
                    if (type == "productSaleUrl") {
                        setIntegrationProduct({ productSaleUrl: null });
                    }
                    setAlert({ type: 'success', title: response?.status_message }) 

                    // toast.success(response?.status_message);
                    hideLoader();

                    setTimeout(() => {
                        handleClose(true)
                    }, 1000);
                }

                else {
                    let errorList = []
                    response?.errors?.link?.map((val) => errorList?.push(val));
                    response?.errors?.pixel_id?.map((val) => errorList?.push(val));
                    response?.errors?.analytics_id?.map((val) => errorList?.push(val));
                    // toast.error(response?.status_message)
                    setAlert({ type: 'error', title: response?.status_message })
                    hideLoader()
                }
            }).catch((err) => {
                hideLoader()
            })
    }
    const handleClose = () => {
        setConnectModal(null)
    }
    const handleSubmit = (e, values, integration) => {
        if(!showModalasd ||( !integration.includes('emailSignupUrl') && !integration.includes('contactFormUrl') && !integration.includes('productSaleUrl'))){
        e.preventDefault();
        showLoader();
        setError([]);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
        const errorList = []
        const formdata = new FormData();
        let formValues = {};
        if (values?.disconnect == 1)
            formValues = { project: slug, tab: "integrations", integration: integration, disconnect: 1 };
        else
            formValues = { ...values, project: slug, integration: integration };

        for (const key in formValues) {
            if (Object.hasOwnProperty.call(formValues, key)) {
                formdata.append(key, formValues[key]);
            }
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(apiUrl + `/project/settings/update`, requestOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {

                    // toast.success(response?.status_message);
                    if (integration == "mailchimp") {
                        dispatch({ type: "SET_MAILCHIMP_INTEGRATION", payload: { ...integrationMailChimpReducer, "disconnect": 1 } })
                        setIntegrationMailchimp({...integrationMailChimpReducer,"disconnect":1})
                    }
                    else if (integration == "convertkit") {
                        dispatch({ type: "SET_CONVERTKIT_INTEGRATION", payload: { ...integrationConvertkitReducer, "disconnect": 1 } })
                        setIntegrationConvertKit({...integrationConvertkitReducer,"disconnect":1})
                    }
                    else if (integration == "facebook") {
                        dispatch({ type: "SET_FACEBOOK_INTEGRATION", payload: { ...integrationFacebookReducer, "disconnect": 1 } })
                        setIntegrationFacebook({...integrationFacebookReducer,"disconnect":1})
                    }
                    else if (integration == "google") {
                        dispatch({ type: "SET_GOOGLE_INTEGRATION", payload: { ...integrationGoogleReducer, "disconnect": 1 } })
                        setIntegrationGoogle({...integrationGoogleReducer,"disconnect":1})
                    }
                    else if (integration == "sendinblue") {
                        dispatch({ type: "SET_SENDINBLUE_INTEGRATION", payload: { ...integrationSendinblueReducer, "disconnect": 1 } })
                        setIntegrationSendinBlue({...integrationSendinblueReducer,"disconnect":1})
                    }
                    else if (integration == 'emailSignupUrl'  ) {
                        dispatch({ type: "SET_ZAPIER_INTEGRATION", payload: { ...integrationEmailReducer,emailSignupUrl : true } })
                        setIntegrationEmail({ emailSignupUrl: true });
                    }
                    else if (integration == 'contactFormUrl') {
                        dispatch({ type: "SET_ZAPIER_INTEGRATION", payload: { ...integrationContactReducer,contactFormUrl : true } })
                        setIntegrationContact({ contactFormUrl: true });
                    }
                    else if (integration =='productSaleUrl') {
                        dispatch({ type: "SET_ZAPIER_INTEGRATION", payload: { ...integrationProductReducer,productSaleUrl : true } })
                        setIntegrationProduct({ productSaleUrl: true });
                    }
                    hideLoader();
                    setAlert({ type: 'success', title: response?.status_message }) 
                    setTimeout(() => {
                        
// console.log("handleSubmit")
                        handleClose(true)

                    }, 1000);
                }

                else {
                    let errorList = []
                    response?.errors?.link?.map((val) => errorList?.push(val));
                    response?.errors?.pixel_id?.map((val) => errorList?.push(val));
                    response?.errors?.analytics_id?.map((val) => errorList?.push(val));
                    response?.errors?.api_key?.map((val) => errorList?.push(val));
                    // setError(errorList)
                    
// console.log(errorList)

                    if (errorList.length !== 0) {
                        
// console.log("Error List", errorList.length)
                        errorList?.map((err) => {
                            return (
                            setAlert({ type: 'error', title: err }) 
                                // toast.error(err)
                            );
                        });
                    }
                    else {
                        setAlert({ type: 'error', title: response?.status_message }) 
                        // toast.error(response?.status_message)
                    }

                    // toast.error(response?.status_message)
                    hideLoader()
                }
            }).catch((err) => {
                hideLoader()
            })
        }
    }
    const handleMeetButtonClick = async () => {
        try {
            localStorage.setItem("last_activity", location.pathname)
            showLoader();
            const response = await fetch(apiUrl + "/google-meet?redirectUrl="+localStorage.getItem("last_activity")+'&project_slug='+encryptedProjectSlug, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            });
  
            if (response.status === 200) {
              const responseData = await response.json();
                  // Redirect to the OAuth URL
                localStorage.setItem("openIntegrationPage",true);

              window.location.href = responseData.data.oauth_url;
                } else {
                  // Handle errors here
                  console.error("API Error:", response.statusText);
                  hideLoader();
                }
  
        } catch (error) {
          // Handle fetch or other errors
          console.error("Error:", error);
          hideLoader();
        }
    };
    const handleDisconnectMeetButtonClick = async () => {
        // if (meetConnected) {
        //     setUnlinkModal(true);
        // } else {
            try {
                showLoader();
                const response = await fetch(apiUrl + "/disconnect-google-account?&project_slug="+encryptedProjectSlug, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                });
    
                const responseData = await response.json();
                if (responseData.status_code === 200) {
                    dispatch({ type: "SET_MEET_CONNECTED", payload: {...meeting_tokens,meet_token: false } });
                    // toast.success(responseData?.status_message);
                    setAlert({ type: 'success', title: responseData?.status_message }) 
                    hideLoader();
                } else {
                // Handle errors here
                hideLoader();
                if (responseData.status_code === 422 && responseData.errors && responseData.errors.google) {
                    // Handle Zoom account error
                    setUnlinkModal({show:true,type:'Google Meet'}); // Set unlinkModal to true
                } else {
                    // Handle other errors
                    console.error("API Error:", responseData.status_message);
                }
                }
    
            } catch (error) {
            // Handle fetch or other errors
            console.error("Error:", error);
            hideLoader();
            }
        // }
    };
    const handleZoom = async () => {
        showLoader();
        const formdata = new FormData();
        formdata.append("project", slug);
        const response = await fetch(apiUrl + "/zoom-connect/request", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: formdata,
        });

        const responseData = await response.json();
        if (responseData?.status_code === 200) {
            hideLoader();
            if (responseData?.data?.url) {   
                window.location.href = responseData?.data?.url
            }else {
                toast?.error('Something went wrong')
            }
        }else {
            setAlert({ type: 'error', title: responseData?.status_message }) 
            // toast?.error(response?.status_message)
            hideLoader();
        }
    }
    const disconnectZoom = async () => {
        try {
            showLoader();
            const formdata = new FormData();
            formdata.append("project", slug);
            const response = await fetch(apiUrl + "/zoom-connect/disconnect", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body:formdata
            });

            const responseData = await response.json();
            if (responseData.status_code === 200) {
                dispatch({ type: "SET_MEET_CONNECTED", payload: { ...meeting_tokens, zoom_token: false } });
                setAlert({ type: 'success', title: responseData?.status_message }) 
                // toast.success(responseData?.status_message);
                hideLoader();
            } else {
                if (responseData.status_code === 422 && responseData.errors && responseData.errors.zoom) {
                    setUnlinkModal({show:true,type:'Zoom'}); // Set unlinkModal to true
                } else {
                    console.error("API Error:", responseData.status_message);
                }
                hideLoader();
            }

        } catch (error) {
            // Handle fetch or other errors
            console.error("Error:", error);
            hideLoader();
        }
    }
    return (
        <>
            <section class="container customize-page-main int-3-b position-relative newpadding overflow-auto">
                <div class="cent-cont-u">
                    <h4 className="settings-section-heading">Integrations</h4>
                    <div class="container mt-3 int-3-u ms-1">
                        <div class="int-u row">

                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                    <img className="integration-card-img" src={integration_google_analytics} alt=""/>
                                </div>
                                <h5 class="mt10-u">Google Analytics</h5>
                                <label class="text-lightgray">Analytics</label>

                                {
                                    (!integrationGoogleReducer?.disconnect) ?
                                        <button type="submit" onClick={() => setParams("googleAnalytics")} className="integration-btns integration-btn-primary" >
                                            <span>Connect</span>
                                            <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                                <img src={returnIcon} alt="" />
                                            </small>
                                        </button>
                                        :

                                        <button class="integration-btns integration-btn-grey customDomainModal"
                                            onClick={() => diconnecting("google", integrationGoogleReducer, "SET_GOOGLE_INTEGRATION")}>
                                            Disconnect
                                        </button>
                                }
                            </div>

                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                    <img className="integration-card-img" src={integration_facebook_analytics} alt="" />
                                </div>
                                <h5 class="mt10-u">Facebook Pixel</h5>
                                <label class="text-lightgray">Tracking</label>

                                {
                                    (!integrationFacebookReducer?.disconnect) ?
                                        <button type="submit" onClick={() => setParams("facebookPixel")} className="integration-btns integration-btn-primary">
                                            <span>Connect</span>
                                            <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                                <img src={returnIcon} alt="" />
                                            </small>
                                        </button>
                                        :

                                        <button class="integration-btns integration-btn-grey customDomainModal" 
                                            onClick={() => diconnecting("facebook", integrationFacebookReducer, "SET_FACEBOOK_INTEGRATION")}>
                                            Disconnect
                                        </button>
                                }
                            </div>

                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                    <img className="integration-card-img" src={integration_mailchimp} alt="" />
                                </div>
                                <h5 class="mt10-u">Mailchimp</h5>
                                <label class="text-lightgray">Email Marketing</label>

                                {
                                    (!integrationMailChimpReducer?.disconnect) ?
                                    <button type="submit" onClick={() => setParams("mailchimp")} className="integration-btns integration-btn-primary">
                                        <span>Connect</span>
                                        <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                        :

                                        <button class="integration-btns integration-btn-grey customDomainModal" 
                                            onClick={() => diconnecting("mailchimp", integrationMailChimpReducer, "SET_MAILCHIMP_INTEGRATION")}>
                                            Disconnect
                                        </button>
                                }
                            </div>

                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                    <img className="integration-card-img" src={integration_convertkit} alt="" />
                                </div>
                                <h5 class="mt10-u">Convertkit</h5>
                                <label class="text-lightgray">Email Marketing</label>

                                {
                                    (!integrationConvertkitReducer?.disconnect) ?
                                    <button type="submit" onClick={() => setParams("convertkit")} className="integration-btns integration-btn-primary">
                                        <span>Connect</span>
                                        <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                        :

                                        <button class="integration-btns integration-btn-grey customDomainModal"
                                            onClick={() => diconnecting("convertkit", integrationConvertkitReducer, "SET_CONVERTKIT_INTEGRATION")}>
                                            Disconnect
                                        </button>
                                }
                            </div>

                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                    <img className="integration-card-img" src={integration_sendinblue} alt="" />
                                </div>
                                <h5 class="mt10-u">SendinBlue</h5>
                                <label class="text-lightgray">Email Marketing</label>

                                {
                                    (!integrationSendinblueReducer?.disconnect) ?
                                    <button type="submit" onClick={() => setParams("sendinblue")} className="integration-btns integration-btn-primary">
                                        <span>Connect</span>
                                        <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                        :

                                        <button class="integration-btns integration-btn-grey customDomainModal"
                                            onClick={() => diconnecting("sendinblue", integrationSendinblueReducer, "SET_SENDINBLUE_INTEGRATION")}
                                        >
                                            Disconnect
                                        </button>
                                }
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                    <img className="integration-card-img" src={integration_meet} alt="" />
                                </div>
                                <h5 class="mt10-u">Google Calendar</h5>
                                <label class="text-lightgray">Booking</label>

                                {
                                    (!meeting_tokens?.meet_token) ?
                                    <button type="submit" onClick={handleMeetButtonClick}className="integration-btns integration-btn-primary">
                                        <span>Connect</span>
                                        <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                        :

                                        <button onClick={handleDisconnectMeetButtonClick}
                                        class="integration-btns integration-btn-grey customDomainModal">
                                        Disconnect
                                        </button>
                                }
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                    <img className="integration-card-img" src={zoomIcon} alt="" />
                                </div>
                                <h5 class="mt10-u">Zoom</h5>
                                <label class="text-lightgray">Bookings</label>
                                {
                                    !meeting_tokens?.zoom_token ?
                                        <button type="submit" onClick={handleZoom}className="integration-btns integration-btn-primary">
                                            <span>Connect</span>
                                            <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                                <img src={returnIcon} alt="" />
                                            </small>
                                        </button> 
                                        :
                                        <button onClick={disconnectZoom} class="integration-btns integration-btn-grey customDomainModal">
                                            Disconnect
                                        </button>
                                }
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                <svg xmlns="http://www.w3.org/2000/svg" height="40" preserveAspectRatio="xMidYMid" viewBox="0 0 256 256" width="40"><path d="m159.998995 128.056308c-.004022 9.512019-1.745546 18.613794-4.914879 27.023786-8.40597 3.169333-17.515789 4.910857-27.03183 4.922922h-.108594c-9.507997-.012065-18.617816-1.749567-27.023786-4.9189-3.1693326-8.40597-4.9148785-17.515789-4.9189005-27.027808v-.112616c.004022-9.507997 1.745546-18.617816 4.9108565-27.019764 8.40597-3.1733546 17.519811-4.9189005 27.03183-4.9229225h.108594c9.516041.004022 18.62586 1.7495679 27.03183 4.9229225 3.169333 8.401948 4.910857 17.511767 4.914879 27.019764zm94.223283-21.388971h-74.716606l52.828908-52.8329301c-4.150699-5.8318932-8.780016-11.3018067-13.82762-16.3494108v-.004022c-5.047604-5.0435821-10.517518-9.6648547-16.345389-13.8155538l-52.83293 52.8329301v-74.71660649c-6.91381-1.16235664-14.016654-1.77772191-21.264289-1.78174391h-.132726c-7.247635.004022-14.346457.61938727-21.264289 1.78174391v74.71660649l-52.8329301-52.8329301c-5.8318932 4.1506991-11.2977847 8.7759937-16.3373448 13.8235978l-.028154.0201099c-5.0395601 5.0395601-9.6568107 10.5014297-13.8075098 16.3252789l52.8369521 52.8329301h-74.71660649s-1.78174391 14.032742-1.78174391 21.288421v.088484c0 7.255679.61536528 14.366567 1.78174391 21.288421h74.71660649l-52.8369521 52.83293c8.3094422 11.659764 18.5132443 21.867588 30.1730086 30.173009l52.8329301-52.836952v74.720628c6.91381 1.158335 14.004588 1.769678 21.240157 1.777722h.18099c7.239591-.008044 14.338413-.619387 21.240157-1.777722v-74.720628l52.836952 52.836952c5.827871-4.150699 11.293763-8.775994 16.341367-13.819576l.012066-.012066c5.03956-5.047604 9.668877-10.517518 13.815554-16.341367l-52.836952-52.83293h74.72465c1.162357-6.909788 1.769678-14.000566 1.777722-21.240157v-.185012c-.008044-7.239591-.615365-14.330369-1.777722-21.240157z" fill="#ff4a00"/></svg>
                                </div>
                                <h5 class="mt10-u">Zapier</h5>
                                <label class="text-lightgray">Zapier Webhook For Email Signup</label>

                                {
                                    (!integrationEmailReducer?.emailSignupUrl) ?
                                    <button type="submit" onClick={() => setParams("zapierEmailSignup")} className="integration-btns integration-btn-primary mt-27">
                                        <span>Connect</span>
                                        <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                        :

                                        <button onClick={() => diconnecting("emailSignupUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION")}
                                        class="integration-btns integration-btn-grey mt-27">
                                        Disconnect
                                        </button>
                                }
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                <svg xmlns="http://www.w3.org/2000/svg" height="40" preserveAspectRatio="xMidYMid" viewBox="0 0 256 256" width="40"><path d="m159.998995 128.056308c-.004022 9.512019-1.745546 18.613794-4.914879 27.023786-8.40597 3.169333-17.515789 4.910857-27.03183 4.922922h-.108594c-9.507997-.012065-18.617816-1.749567-27.023786-4.9189-3.1693326-8.40597-4.9148785-17.515789-4.9189005-27.027808v-.112616c.004022-9.507997 1.745546-18.617816 4.9108565-27.019764 8.40597-3.1733546 17.519811-4.9189005 27.03183-4.9229225h.108594c9.516041.004022 18.62586 1.7495679 27.03183 4.9229225 3.169333 8.401948 4.910857 17.511767 4.914879 27.019764zm94.223283-21.388971h-74.716606l52.828908-52.8329301c-4.150699-5.8318932-8.780016-11.3018067-13.82762-16.3494108v-.004022c-5.047604-5.0435821-10.517518-9.6648547-16.345389-13.8155538l-52.83293 52.8329301v-74.71660649c-6.91381-1.16235664-14.016654-1.77772191-21.264289-1.78174391h-.132726c-7.247635.004022-14.346457.61938727-21.264289 1.78174391v74.71660649l-52.8329301-52.8329301c-5.8318932 4.1506991-11.2977847 8.7759937-16.3373448 13.8235978l-.028154.0201099c-5.0395601 5.0395601-9.6568107 10.5014297-13.8075098 16.3252789l52.8369521 52.8329301h-74.71660649s-1.78174391 14.032742-1.78174391 21.288421v.088484c0 7.255679.61536528 14.366567 1.78174391 21.288421h74.71660649l-52.8369521 52.83293c8.3094422 11.659764 18.5132443 21.867588 30.1730086 30.173009l52.8329301-52.836952v74.720628c6.91381 1.158335 14.004588 1.769678 21.240157 1.777722h.18099c7.239591-.008044 14.338413-.619387 21.240157-1.777722v-74.720628l52.836952 52.836952c5.827871-4.150699 11.293763-8.775994 16.341367-13.819576l.012066-.012066c5.03956-5.047604 9.668877-10.517518 13.815554-16.341367l-52.836952-52.83293h74.72465c1.162357-6.909788 1.769678-14.000566 1.777722-21.240157v-.185012c-.008044-7.239591-.615365-14.330369-1.777722-21.240157z" fill="#ff4a00"/></svg>
                                </div>
                                <h5 class="mt10-u">Zapier</h5>
                                <label class="text-lightgray">Zapier Webhook For Product Sale &nbsp;</label>

                                {
                                    (!integrationProductReducer?.productSaleUrl) ?
                                    <button type="submit" onClick={() => setParams("zapierProductSale")} className="integration-btns integration-btn-primary mt-27">
                                        <span>Connect</span>
                                        <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                        :

                                        <button onClick={() => diconnecting("productSaleUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION")}
                                        class="integration-btns integration-btn-grey mt-27">
                                        Disconnect
                                        </button>
                                }
                            </div>
                            <div class="col-md-4 col-lg-4 col-sm-12 integration-card mb-3 me-3">
                                <div className="icon-shadow">
                                <svg xmlns="http://www.w3.org/2000/svg" height="40" preserveAspectRatio="xMidYMid" viewBox="0 0 256 256" width="40"><path d="m159.998995 128.056308c-.004022 9.512019-1.745546 18.613794-4.914879 27.023786-8.40597 3.169333-17.515789 4.910857-27.03183 4.922922h-.108594c-9.507997-.012065-18.617816-1.749567-27.023786-4.9189-3.1693326-8.40597-4.9148785-17.515789-4.9189005-27.027808v-.112616c.004022-9.507997 1.745546-18.617816 4.9108565-27.019764 8.40597-3.1733546 17.519811-4.9189005 27.03183-4.9229225h.108594c9.516041.004022 18.62586 1.7495679 27.03183 4.9229225 3.169333 8.401948 4.910857 17.511767 4.914879 27.019764zm94.223283-21.388971h-74.716606l52.828908-52.8329301c-4.150699-5.8318932-8.780016-11.3018067-13.82762-16.3494108v-.004022c-5.047604-5.0435821-10.517518-9.6648547-16.345389-13.8155538l-52.83293 52.8329301v-74.71660649c-6.91381-1.16235664-14.016654-1.77772191-21.264289-1.78174391h-.132726c-7.247635.004022-14.346457.61938727-21.264289 1.78174391v74.71660649l-52.8329301-52.8329301c-5.8318932 4.1506991-11.2977847 8.7759937-16.3373448 13.8235978l-.028154.0201099c-5.0395601 5.0395601-9.6568107 10.5014297-13.8075098 16.3252789l52.8369521 52.8329301h-74.71660649s-1.78174391 14.032742-1.78174391 21.288421v.088484c0 7.255679.61536528 14.366567 1.78174391 21.288421h74.71660649l-52.8369521 52.83293c8.3094422 11.659764 18.5132443 21.867588 30.1730086 30.173009l52.8329301-52.836952v74.720628c6.91381 1.158335 14.004588 1.769678 21.240157 1.777722h.18099c7.239591-.008044 14.338413-.619387 21.240157-1.777722v-74.720628l52.836952 52.836952c5.827871-4.150699 11.293763-8.775994 16.341367-13.819576l.012066-.012066c5.03956-5.047604 9.668877-10.517518 13.815554-16.341367l-52.836952-52.83293h74.72465c1.162357-6.909788 1.769678-14.000566 1.777722-21.240157v-.185012c-.008044-7.239591-.615365-14.330369-1.777722-21.240157z" fill="#ff4a00"/></svg>
                                </div>
                                <h5 class="mt10-u">Zapier</h5>
                                <label class="text-lightgray">Zapier Webhook For Contact Form</label>

                                {
                                    (!integrationContactReducer?.contactFormUrl) ?
                                    <button type="submit" onClick={() => setParams("zapierContactForm")} className="integration-btns integration-btn-primary mt-27">
                                        <span>Connect</span>
                                        <small className="badge bg-primary-dark text-dark  d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                        :

                                        <button onClick={() => diconnecting("contactFormUrl", integrationzapierReducer, "SET_ZAPIER_INTEGRATION")}
                                        class="integration-btns integration-btn-grey mt-27">
                                        Disconnect
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {showModalasd && <Teammoadal showModal={showModalasd} text={'Integrations is a Pro feature. <br /> Upgrade to Zaap Pro to unlock this feature.'} isUltra={false}/>}
            </section>
{/* <section className="container settings-section"style={{padding:"10px"}}>
            
            <div class="container">
                <h1 class="acount-header mb-0" >Integrations</h1>
            </div>
            <div class="container mb-5 ">
                <div className='d-flex justify-content-between align-items-center mb-3' style={{background:"#EEEDEE",borderRadius:"12px",padding:'12px'}}>
                    <div class="form-group d-flex justify-content-start align-items-center w-100">
                        <img src={payout_settings_stripe} width="40px" alt="" />
                        <p className="text-gray font-weight-500 mb-0 ms-2">Stripe</p>    
                    </div>
                    {stripeConnectReducer?.stripe == 0 ? 
                        <button type="button" className="btn btn-semi-rounded" style={{background: '#FAFAFA'}} onClick={connectStripe}><b>Connect</b></button> : 
                        <button type="button" className="btn btn-semi-rounded" style={{background: '#FAFAFA'}} onClick={disconnectSripe}><b>Disconnect</b></button>
                    }
                    </div>
                <div className='d-flex justify-content-between align-items-center mb-3' style={{background:"#EEEDEE",borderRadius:"12px",padding:'12px'}}>
                    <div class="form-group d-flex justify-content-start align-items-center w-100">
                        <img src={payout_settings_paypal} width="40px" alt="" />
                        <p className="text-gray font-weight-500 mb-0 ms-2">Paypal</p>    
                    </div>
                    {stripeConnectReducer?.paypal == 0 ? 
                        <button type="button" className="btn btn-semi-rounded" style={{background: '#FAFAFA'}} onClick={connectPayPal}><b>Connect</b></button>:
                        <button type="button" className="btn btn-semi-rounded" style={{background: '#FAFAFA'}} onClick={disconnectModal}><b>Disconnect</b></button>
                    }
                    </div>
            </div>
            {payoutDisconnectModal && <DisonnectModal show={payoutDisconnectModal} setShow={setpayoutDisconnectModal} disconnect={onDisconnect} />}

            </section> */}
        {connectModal == "zapierEmailSignup" && <ConnectZapierModal type={"emailSignupUrl"} text="Email Signup" show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose}/>}
        {connectModal == "zapierProductSale" && <ConnectZapierModal type={"productSaleUrl"} text="Product Sale" show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose}/>}
        {connectModal == "zapierContactForm" && <ConnectZapierModal type={"contactFormUrl"} text="Contact Form" show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose}/>}
        {connectModal == "googleAnalytics" && <GoogleAnalyticsModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose}/>}
        {connectModal == "facebookPixel" && <FacebookPixelModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose}/>}
        {connectModal == "mailchimp" && <MailchimpModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose}/>}
        {connectModal == "convertkit" && <ConvertkitModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose}/>}
        {connectModal == "sendinblue" && <SendinblueModal show={true} setShowCustomizeParent={setShowCustomizeParent} handleSubmit={handleSubmit} error={error} handleClose={handleClose}/>}
        <ConnectCalendarModal show={unlinkModal?.show} setShow={setUnlinkModal} type={unlinkModal?.type} TwoButtons={false} text={`This ${unlinkModal?.type} Account is being used for existing products. Please unlink ${unlinkModal?.type} from your products before continuing`} />
        </>
    )
}

export default IntegrationsPage;
