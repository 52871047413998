import { ReactSortable } from "react-sortablejs";
import returnIcon from "../../../../assets/img/modals/return.svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../loader";
import { apiUrl } from "../../../../utils/url";
import FormFieldError from "../../../errors/formFieldError";
import tickIconPrimary from "../../../../assets/img/white-tick-icon.svg";
import ErrorMessage from "../../../errors/errorMessage";
import CroppieModal from "../../../croppie-modal";
import { toast } from "react-toastify";
import removeicon from "../../../../assets/img/products/removeicon.svg";
import productCheckbox from "../../../../assets/img/products/product-checkbox.svg"
import addFaqIcon from "../../../../assets/img/products/add-faq-btn.svg";

const DetailsPage = ({ type, data, count, handleSubmit , error, setError }) => {
    // const [title, setTitle] = useState(data?.name);
    // const [short_description, setShort_Description] = useState(data?.short_description);
    // const [price_type, setPriceType] = useState(data?.price_type);
    // const [price, setPrice] = useState(data?.price);
    // const [min_price, setMin_Price] = useState(data?.price_type !== "suggested_price" ? 0 : data?.price );
    // const [suggested_price, setSugested_Price] = useState(data?.suggested_price);
    // const [currency, setCurrency] = useState(data?.currency);
    // const [errorMessage, setErrorMessage] = useState('');
    // const [product_slug, setProductSlug] = useState(data?.slug);
    const [cover_image, setCover_Image] = useState(data?.cover_images ?? []);
    const [croppie_image, setcroppie_image] = useState({ url: "", id: null });
    const [croppie, setCroppie] = useState(null);
    const [deleted_cover_image, setDeleted_cover_image] = useState([])
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(null);
    const { slug } = useSelector(state => state?.slugReducer);
    const values = useSelector(state => state?.productDetailReducer);
    const tierValues = useSelector(state => state?.productTierReducer);
    const handlePriceTypeChange = (a) => {
        // setPriceType(a.target.value)
        if (a.target.value == 'free') {
            // setPrice(0);
            // setMin_Price(0);
            // setSugested_Price(0);
            dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values,price:0, min_price:0, suggested_price:0, [a.target.name]:a.target.value} })
            // changeDelay();
        } else if (a.target.value == 'single_payment') {
            // setMin_Price(0);
            // setSugested_Price(0);
            dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values, min_price:0, suggested_price:0, [a.target.name]:a.target.value} })
        }else {
            dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values, [a.target.name]:a.target.value} })
        }
    };
    const handlePriceChange = (e) => {
        const sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');

        const singleDecimalValue = sanitizedValue.replace(/(\..*?)\..*/g, '$1');
        if (e.target.name === "price") {
            // setPrice(singleDecimalValue);
            // setMin_Price(0);
            // setSugested_Price(0);
            dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values, price:singleDecimalValue, min_price:0, suggested_price:0} })
        }
        else if (e.target.name === "min_price") {
            // setMin_Price(singleDecimalValue);
            // setPrice(0);
            dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values, price:0, min_price:singleDecimalValue} })
        }
        else if (e.target.name === "suggested_price") {
            // setSugested_Price(singleDecimalValue);
            // setPrice(0);
            dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values, price:0, suggested_price:singleDecimalValue} })
        }
        // changeDelay();
    };
    // useEffect(()=>{
    //     if (data) {
    //         console.log("data",data)
    //         setTitle(data?.name);
    //         setShort_Description(data?.short_description)
    //         setPriceType(data?.price_type);
    //         setPrice(data?.price);
    //         setMin_Price(data?.price_type !== "suggested_price" ? 0 : data?.price );
    //         setSugested_Price(data?.suggested_price);
    //         setCurrency(data?.currency);
    //         setProductSlug(data?.slug);
    //         setCover_Image(data?.cover_images ?? []);
    //     }
    // },[data])
    const handleTitleChange = (e) => {
        setTitle(e.target.value)
        let slug = e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
        slug = slug.replaceAll(' ', '-');
        setProductSlug(slug)
    }
    const handleopenmodal = (e) => {
        setcroppie_image({ url: "", id: null, name: null, type: null });
        generate_image_preview(e.target);
        hideLoader();
    };
    const [showCroppieModal, setshowCroppieModal] = useState(null);

    function generate_image_preview(input) {
        showLoader();
        if (input.files && input.files[0]) {
            let reader = new FileReader();
            if (input.files[0].size >= 5242880) {
            $("#image_file_input").val("");
            let notification_container = $(".notification-container");
            toast.error("File Size Should not exceed 5MBs");
            // showModal();
            return;
            }
            let val = input.files[0].name;
            let valid_extensions = ["png", "jpg", "jpeg"];
            let extension =
            val.substring(val.lastIndexOf(".") + 1, val.length) || val;
            if (!valid_extensions.includes(extension)) {
            $("#image_file_input").val("");
            let notification_container = $(".notification-container");
            toast.error("Invalid Image File. Should be png, jpg or jpeg");
            // showModal();
            return;
            }
            reader.onload = (event) => {
            setcroppie_image({
                ...croppie_image,
                url: event.target.result,
            });
            console.log(setcroppie_image);
            console.log("setcroppie_image", croppie_image);
            setshowCroppieModal(true);
            setTimeout(() => {
                const el = document.getElementById("image_demo2");
                try {
                console.log("el", el);
                if (el) {
                    var croppieInstance = new Croppie(el, {
                    enableExif: true,
                    viewport: {
                        width: 850,
                        height: 470,
                        type: "square",
                    },
                    boundary: {
                        width: 870,
                        height: 570,
                    },
                    });
                    croppieInstance
                    .bind({
                        url: event.target.result,
                    })
                    .then(function () {
                        console.log("jQuery bind complete");
                        setTimeout(() => { }, 300);
                    })
                    .catch(() => {
                        croppieClose();
                    });
                    setCroppie(croppieInstance);
                }
                } catch { }
            }, 500);
            };
            reader.readAsDataURL(input.files[0]);
        }
    }
    const croppedImage = () => {
        if (croppie !== null) {
          croppie
            .result({
              type: "canvas",
              size: "original",
              format: "png",
              quality: 1,
            })
            .then((response) => {
              const tempObj = { image_url: response };
              croppie
                .result({
                  size: "viewport",
                  format: "png",
                  quality: 1,
                  type: "blob",
                })
                .then((response) => {
                //   setCover_Image([
                //     ...cover_image,
                //     {
                //       ...tempObj,
                //       img: response
                //     }]
                //   )
                dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values, cover_image:[...values?.cover_image,response]} })
                // changeDelay();  
                  croppieClose()
                });
            });
        }
    };
    const handleHideModal = () => {
        setshowCroppieModal(false);
        setCroppie(null);
        setcroppie_image({ url: "", id: null });
    };
    const croppieClose = () => {
        showLoader();
        setshowCroppieModal(false);
        setCroppie(null);
        setTimeout(() => {
            hideLoader();
        }, 400);
    };
    // function Removecardfun({ i, id }) {
    //     setDeleted_cover_image([...deleted_cover_image, id])
    //     if (!id) {
    //         setDeleted_cover_image([...deleted_cover_image, 'old_cover_image'])
    //     }
    //     const covimg = [...cover_image]
    //     covimg.splice(i, 1)
    //     setCover_Image(covimg)
    // }
    function Removecardfun({ i, id }) {
        const updatedCoverImage = [...values.cover_image];
        updatedCoverImage.splice(i, 1);

        const updatedDeletedCoverImage = [...values.deleted_cover_image];
        if (!id) {
            updatedDeletedCoverImage.push('old_cover_image');
        } else {
            updatedDeletedCoverImage.push(id);
        }
        dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values, cover_image: updatedCoverImage, deleted_cover_image: updatedDeletedCoverImage} });
        // changeDelay();
    }
    const updateDetails = (reducer) => {
        // const obj = {
        //     title:values?.title,
        //     short_description:values?.short_description,
        //     product_slug:values?.product_slug,
        //     price_type:values?.price_type,
        //     price:values?.price,
        //     min_price:values?.min_price,
        //     suggested_price:values?.suggested_price,
        //     currency:values?.currency,
        //     cover_image:values?.cover_image,
        //     deleted_cover_image:values?.deleted_cover_image,
        // };
        let tab = reducer == 'values'?'details':'tier';
        handleSubmit(null,reducer,tab);

    }
    function changeDelay(reducer) {
        if (timer) {
          clearTimeout(timer);
          setTimer(null);
        }
        setTimer(
          setTimeout(() => {
            updateDetails(reducer)
          }, 3000)
        );
    };
    // useEffect(()=>{
    //     if (count > 0) {
            
    //     }
    // },[values])
   
    const handleChange = (e) => {
        if (e.target.name == 'product_slug') {
            let slug = e.target.value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
            slug = slug.replaceAll(' ', '-');
            e.target.value = slug;
            setProductSlug(slug)
        }
        dispatch({ type: "SET_PRODUCT_DETAIL", payload: { ...values, [e.target.name]: e.target.value } })
        // changeDelay();
    }
    useEffect(()=>{
        if (data?.cover_image) {
            // setCover_Image(prevCoverImages => [...prevCoverImages, {images:data?.cover_image}]);
            dispatch({ 
                type: "SET_PRODUCT_DETAIL", 
                payload: { 
                    ...values, 
                    cover_image: [...values.cover_image, { images: data.cover_image }] 
                } 
            });
         }
    },[data?.cover_image])
    // console.log("count",count)
    useEffect(() => {
        if (count > 0) {
            changeDelay(values);
        }
    }, [values]);
    useEffect(() => {
        if (count > 0) {
            changeDelay(tierValues);
        }
    }, [tierValues]);
    function handleAddTier() {
        var newdata = [...tierValues?.tiers];
        newdata.push({ tier_name: "", price: 0, type: "monthly" });
        dispatch({ type: "SET_PRODUCT_TIER", payload: {  ...tierValues, tiers:newdata } })
      }
    const delete_tier = (index) => {
        const tiers = [...tierValues?.tiers];
        tiers.splice(index, 1)
        dispatch({ type: "SET_PRODUCT_TIER", payload: {  ...tierValues, tiers:tiers } })
    };
    const handleChangeTier = (value, index, field) => {
        const tiers = [...tierValues?.tiers];
        tiers[index][field] = value;
        dispatch({ type: "SET_PRODUCT_TIER", payload: { ...tierValues, tiers: tiers } })
    };
    return (
        <div class="product-pages">
            <h4 class="productHead1">Details</h4>
                <form id="newProductModal" name="product_type" method="post" role="form">
                    <div className="notification-container"></div>
                    <div>
                        <ErrorMessage error={error} />
                        <div className="form-group mt-20px product-form-group">
                            <label className="productlabels">
                                Product Name
                                <div className="product-labels-description">The title of your product</div>
                            </label>
                            <div className="product-form-group-inputs">
                            <input
                                value={values?.title}
                                onChange={handleChange}
                                type="text"
                                className="zaapGlobal-input"
                                name="title"
                                placeholder="My Awesome Product"
                            />
                            </div>
                            <FormFieldError error={error?.title} />
                        </div>
                        <div className="form-group mt-20px product-form-group">
                            <label className="productlabels">
                                Description
                                <div className="product-labels-description">
                                Give a short description of your product
                                </div>
                            </label>
                            <textarea value={values?.short_description} onChange={handleChange}
                                type="text"
                                className="zaapGlobal-input hidden-scroll  product-form-group-inputs"
                                name="short_description"
                                placeholder="Got my new awesome course!"
                                style={{height:"80px"}}
                            >
                            </textarea>
                        </div>

                        <div className="form-group mt-20px product-form-group">
                            <label className="productlabels">
                                Product URL
                            </label>
                            <div className="zaapGlobal-input d-flex align-items-center  product-form-group-inputs">
                                <div>
                                    zaap.bio/{slug}/
                                </div>
                                <input className="input-style2" maxLength={40} value={values?.product_slug?.toLowerCase()} onChange={handleChange} autoComplete="off" type="text" name="product_slug"/>
                            </div>
                        </div>

                        <div className="form-group mt-20px">
                                    <h4 class="productHead1">Images</h4>
                                    <div className="preview-zone hidden "></div>
                                    <button type="button" className="product-upload-images ">
                                        Upload New
                                        <div className="plus-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0C6.41421 0 6.75 0.335786 6.75 0.75V5.25H11.25C11.6642 5.25 12 5.58579 12 6C12 6.41421 11.6642 6.75 11.25 6.75H6.75V11.25C6.75 11.6642 6.41421 12 6 12C5.58579 12 5.25 11.6642 5.25 11.25V6.75H0.75C0.335786 6.75 0 6.41421 0 6C0 5.58579 0.335786 5.25 0.75 5.25H5.25V0.75C5.25 0.335786 5.58579 0 6 0Z" fill="#333333"/>
                                            </svg>

                                        </div>
                                        <input
                                            type="file"
                                            className="dropzone dropzone4 inset-0" 
                                            name="cover_image"
                                            accept=".png,.jpg,.jpeg"
                                            id="image_file_input"
                                            onChange={handleopenmodal}
                                        />
                                    </button>
                            </div>
                            <div >
                                <ReactSortable className="prevContainer " list={cover_image} setList={setCover_Image}>
                                {cover_image?.map((e, i) => {
                                    return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flexWrap: "wrap" }}>
                                                <div className="product-img-preview" key={i}>
                                                    <img className="w-100" draggable src={e.image_url ? e.image_url : e.images}/>
                                                    <span className="img-preview-delete">
                                                        <img onClick={() => Removecardfun({ i, id: e.id })} src={removeicon} />
                                                    </span>
                                                </div>
                                            <div>
                                        </div>
                                    </div>;
                                })}

                                </ReactSortable>
                            </div>
                    </div>
                    {
                    type?.toLowerCase() !== 'membership' ? 
                    <>
                    <div
                        id="productPricing"
                        className="form-group"
                        aria-labelledby="headingOne"
                    >
                        <div className="form-group w-100 p-0 mt-3">
                            <h4 >
                                Pricing
                            </h4>
                            <div className="up-in-toggle bg-white price_type_check mt-2 p-0">
                                <input
                                    checked={values?.price_type == 'free' ? true : false}
                                    type="radio"
                                    id="switch_left"
                                    name="price_type"
                                    value="free"
                                    onChange={handlePriceTypeChange}
                                />
                                <label
                                    htmlFor="switch_left"
                                    className="d-flex align-items-start gap-2"
                                >
                                    <span>
                                        <img src={productCheckbox} alt="" />
                                    </span>
                                    <span className="">
                                        <h5 className="m-0 font-weight-500 pricing-text productlabel2">
                                            Free
                                        </h5>
                                    <h6 className="productlabel3 mt-0">
                                        Allow access for free.
                                    </h6>
                                    </span>
                                </label>
                                <input
                                    checked={values?.price_type == 'single_payment' ? true : false}
                                    type="radio"
                                    id="switch_right"
                                    name="price_type"
                                    value="single_payment"
                                    onChange={handlePriceTypeChange}
                                />
                                <label
                                    htmlFor="switch_right"
                                    className="d-flex align-items-start gap-2"
                                >
                                    <span>
                                        <img src={productCheckbox} alt="" />
                                    </span>
                                    <span className="">
                                        <h5
                                            className="m-0 font-weight-500 pricing-text productlabel2"
                                        >
                                            Single Payment
                                        </h5>
                                        
                                    <h6  className="productlabel3 mt-0">
                                        Charge a one-time fee.
                                    </h6>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="form-group w-100 p-0 mt-3">
                            <div className="up-in-toggle bg-white price_type_check p-0 pay-class">
                                <input checked={values?.price_type == 'Pay_what_you_want' ? true : false}
                                    type="radio"
                                    id="payment"
                                    name="price_type"
                                    value="Pay_what_you_want"
                                    onChange={handlePriceTypeChange}
                                />
                                <label
                                    htmlFor="payment"
                                    className="d-flex align-items-start gap-2"
                                >
                                    <span>
                                        <img src={productCheckbox} alt="" />
                                    </span>
                                    <span className="">
                                        <h5
                                            className="m-0 font-weight-500 pricing-tex productlabel2"
                                        >
                                            Pay what you want
                                        </h5>
                                        
                                    <h6  className="productlabel3 mt-0">
                                        Let customers choose
                                    </h6>
                                    </span>
                                </label>
                                <input
                                    type="radio"
                                    id="membership"
                                    // name="price_type"
                                    value="membership"
                                    disabled
                                />
                                <label
                                    htmlFor="membership"
                                    className="d-flex align-items-start gap-2 membershipstyle"
                                >
                                    <span>
                                        <img src={productCheckbox} alt="" />
                                    </span>
                                    <span className="">
                                        <h5
                                            className="m-0 font-weight-500 pricing-text productlabel2"
                                            style={{
                                                color: "#b1b1b1",
                                            }}
                                        >
                                            Payment Plan
                                        </h5>
                                        
                                    <h6
                                        className="productlabel3 mt-0"
                                        style={{
                                            color: "#cfcfcf",
                                        }}
                                    >
                                        COMING SOON
                                    </h6>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    {values?.price_type == "single_payment" && (
                        <div className="form-group mt-3">
                            <label className="productlabels">
                                Price
                                <small className="text-red">*</small>
                            </label>
                            <div>
                                <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                    <span>
                                        {values?.currency == "eur" ? '€' : values?.currency == "gbp" ? '£' : '$'}
                                    </span>
                                    <div className="w-100">
                                        <input value={values?.price} onChange={handlePriceChange} className="" type="text" name="price" placeholder="0" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {values?.price_type == "Pay_what_you_want" && (
                        <div>
                            <div className="d-flex gap-3">
                                <div className="w-100">
                                    <div className="form-group mt-3">
                                        <label className="productlabels">
                                                Minimum Price
                                                <small className="text-red">
                                                    *
                                                </small>
                                        </label>
                                        <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                            <span>
                                                {values?.currency == "eur" ? '€' : values?.currency == "gbp" ? '£' : '$'}
                                            </span>
                                            <div className="w-100">
                                                <input value={values?.min_price} onChange={handlePriceChange} className="" type="text" name="min_price" placeholder="0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100">
                                    <div className="form-group mt-3">
                                        <label className="productlabels">
                                                Suggested Price 
                                                <small className="text-red">
                                                    *
                                                </small>
                                        </label>
                                        <div className="d-flex w-100 align-items-center gap-1 zaapGlobal-input">
                                            <span>
                                                {values?.currency == "eur" ? '€' : values?.currency == "gbp" ? '£' : '$'}
                                            </span>
                                            <div className="w-100">
                                                <input value={values?.suggested_price} onChange={handlePriceChange} className="" type="text" name="suggested_price" placeholder="0" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(values?.price_type == "Pay_what_you_want") || (values?.price_type == "single_payment") ?
                        <div className="currency-dropdown mt-4">
                            <div className="d-flex flex-column">
                                <span>Currency</span>
                                <small className="text-gray">This can't be edited later</small>
                            </div>
                            <div className="currency-select-box">
                                <select name="currency" id="currency" disabled={true} value={values?.currency}>
                                        <option value={values?.currency}>{values?.currency == "eur" ? '€' : values?.currency == "gbp" ? '£' : '$' + values?.currency?.toUpperCase()}</option>
                                </select>
                            </div>
                        </div> : ''
                    }
                    </>
                    :
                    <div>
                        <h4 class="productHead1">Pricing</h4>
                        {tierValues?.tiers?.map((value, index) => (
                            <div className="d-flex gap-1 align-items-end" key={index}>
                                <div className="w-100">
                                    <label htmlFor="" className="tier-label">Tier Name</label>
                                    <input type="text" onChange={(e) => handleChangeTier(e.target.value, index, 'tier_name')} className="zaapGlobal-input" value={value?.tier_name} />
                                </div>
                                <div className="w-100">
                                    <label htmlFor="" className="tier-label">Price</label>
                                    <input type="number" onChange={(e) => handleChangeTier(e.target.value, index, 'price')} className="zaapGlobal-input" value={value?.price} />
                                </div>
                                <div>
                                <div className="product-action-dropdown dropdown-toggle noselect d-flex align-items-center justify-content-end" id={`product-action-dropdown-${index}`} data-bs-toggle="dropdown" aria-expanded="false">
                                    {value?.type}
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                                            <path d="M1 1.5L4 4.5L7 1.5" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                </div>
                                <ul className="dropdown-menu product-dropdown-menu" aria-labelledby={`product-action-dropdown-${index}`}>
                                    <li className="dropdown-item" onClick={() => handleChangeTier(value?.type === 'monthly' ? 'yearly' : 'monthly', index, 'type')}>
                                        {value.type === 'monthly' ? 'Yearly' : 'Monthly'}
                                    </li>
                                </ul>
                                </div>
                                <div className="box-grey cursor-pointer">
                                    <img src={removeicon} alt="Remove" onClick={() => delete_tier(index)} />
                                </div>
                            </div>
                        ))}
                        <button type="button" className="add-question-btn mt-15px" onClick={handleAddTier}>
                            <span className="m-0">Add Tier</span>
                            <img src={addFaqIcon} width="18px" alt="" />
                        </button>
                    </div>
                    }
                    {/* <div class="text-center mt-4 d-flex submit-buttons-fixed">
                        <button type="button" onClick={(e)=>updateDetails(e)} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                            <b class="me-2 productlabel2">Save Changes</b>
                            <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                <img src={returnIcon} alt="" />
                            </small>
                        </button>
                    </div> */}
                </form>
                <CroppieModal show={showCroppieModal} handleHide={handleHideModal} croppieClose={croppieClose} croppedImage={croppedImage} modalWidth={500} productCroppie={"productCroppie"} />
        </div >
    )
}
export default DetailsPage