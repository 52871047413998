import { useState } from "react";
import { toast } from "react-toastify"
import { useAlert } from "../../contexts/alertContext";

export const audiencePosts = async (setAlert = null, apiUrl, hideLoader, setOptions, isFrom) => {

    try {
        const res = await  fetch(apiUrl, setOptions)
        const response = await res.json();
        if(isFrom == 'custom_message'){
            return await response;
        }
// console.log("API Response", response)
        if (response?.status_code == 200) {
            if (isFrom == "export")  window.open(response?.data?.redirect_url, '_blank').focus();
            if(response?.status_message) {
                if (setAlert) {   
                    setAlert({ type: 'success', title: response?.status_message }) 
                }else {
                    toast.success(response?.status_message);
                }
            }
            hideLoader();
            return await response?.data
        }
        else {
            if (setAlert) {   
                setAlert({ type: 'error', title: response?.status_message }) 
            }else {
                toast?.error(response?.status_message)
            }
            hideLoader();
            return false
        }
    }
    catch (error) {
        toast?.error(error)
        hideLoader()

        return false
    }
}