import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import DetailsPage from './details-page'
import dragIcon from "../../../../assets/img/page/drag-icon.svg";
import { hideLoader, showLoader } from '../../../loader';
import { apiUrl, pageUrl, protocol } from '../../../../utils/url';
import ContentPage from './content-page';
import plusIcon from "../../../../assets/img/thin-plus-icon.svg";
import CheckoutPage from './checkout-page';
import BlockIcon from "../../../../assets/img/page/blockicon.svg";
import gearPageIcon from "../../../../assets/img/page/gear.svg";
import trashIcon from "../../../../assets/img/page/trash.svg";
import { useDispatch, useSelector } from 'react-redux';

function ProductTabs({count, editProduct, setIframeURL, iframeIndex, setIframeIndex, showProductsSectionModal, data, error, setError, onDelete, editModal, product_id }) {
    const dispatch = useDispatch();
    const { slug } = useSelector(state => state?.slugReducer);
    const values = useSelector(state => state?.productReducer);
    const [type, setType] = useState(null);
    useEffect(()=>{
        if (data?.product_type) {
            setType(data?.product_type?.toLowerCase());
        }
    },[data?.product_type])
    const draggableIcon = () => <img src={dragIcon} id="drag" className="me-3 cursor-grab" alt="" />
    const handleSubmit = (e,values,tab) => {
        if (count > 0) {
        e?.preventDefault();
        const api = apiUrl + "/ProductFlow/update-setting";
        // showLoader()
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            for (const key in values) {
                if (Object.hasOwnProperty.call(values, key)) {
                    if(key == 'product_content_data'){
                        values?.product_content_data.forEach((fileObj, index) => {
                            // const fileBlob = new Blob([fileObj.content], { type: fileObj.type });
                            formdata.append(`product_content_data[${index}][type]`, fileObj?.type);
                            formdata.append(`product_content_data[${index}][content]`, fileObj.content);
                            formdata.append(`product_content_data[${index}][name]`, fileObj?.name);
                          });
                    }else if(key == 'tiers'){
                            values?.tiers.forEach((val, index) => {
                                // formdata.append(key, JSON.stringify(values?.tiers));
                                formdata.append(`tiers[${index}][type]`, val?.type);
                                formdata.append(`tiers[${index}][price]`, val.price);
                                formdata.append(`tiers[${index}][tier_name]`, val?.tier_name);
                              });
                    }else{
                        formdata.append(key, values[key]);
                    }
                }
            }
            if (tab == "details") {
                if(values?.cover_image){
                    formdata.delete('cover_image');
                    values?.cover_image.map((val, key) => {
                        if (val?.img) {
                          formdata.append("cover_image[]", new File([val.img], "image.png"));
                        }
                    });
                }
            }
            formdata.append("project", slug);
            formdata.append("tab", tab);
            formdata.append("product_id", product_id);
            const setOptions = {
                method: "POST",
                body: formdata,
                headers: myHeaders,
            }
            fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setError([])
                    setTimeout(() => {
                        // editProduct(product_id)
                        setIframeURL(protocol + '' + pageUrl + '/' + slug + '/' + data?.slug  + '?iframe=1');
                        setIframeIndex(iframeIndex + 1);
                        hideLoader();
                    }, 1000);
                    toast.success(response?.status_message)
                }else{
                    setError(Object.values(response?.errors));
                    if (Object.keys(response?.errors).length > 0)
                        $(".modal-body").scrollTop(0);
                    hideLoader();
                }
            // hideLoader();
          }).catch(() => {
            // hideLoader();
          });
        }
    };
    return (
        <Tabs
            defaultActiveKey="product"
            id="justify-tab-example"
            className="mb-3 up-in-toggle main-btn-header btnTxtDark border-less"
            justify
        >
            <Tab
                eventKey="product"
                title={"Product"}
                className="product-nav-link"
            >
                <DetailsPage type={type} data={data} count={count} handleSubmit={handleSubmit} error={error} setError={setError} />
            </Tab>
            <Tab
                eventKey="content"
                className="up-in-toggle"
                title={type == 'meeting' ? 'Availability' : data?.product_type == 'membership' ? 'Membership' : 'Content'}
            >
                <ContentPage data={data} count={count} handleSubmit={handleSubmit} error={error} setError={setError} />
            </Tab>
            <Tab
                eventKey="website"
                className="up-in-toggle"
                title={"Website"}
            >
                <div className='Edit-area'>
                    <div className='product-blocks-main' id="product-blocks-main">
                        {data?.product_blocks?.length > 0 && data?.product_blocks?.map((val, index) => {
                            if (val?.type == "three_box") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">

                                                    <h5 className="editor-link-headings mb-0">Feature</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "horizontal") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">
                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "vertical") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">
                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "faq") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">

                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.faq_heading}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "reviews") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">

                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (val?.type == "pricing") {
                                return (
                                    <div data-block={val?.id} className='mt-20px product-block'>
                                        <div className="editor-linku" id="folder-card">
                                            <div className="d-flex align-items-center editor-link-first-half">
                                                {draggableIcon()}
                                                <img src={BlockIcon} className="me-3" alt="" />
                                                <div className="d-flex align-items-start justify-content-center flex-column">

                                                    <h5 className="editor-link-headings mb-0">{JSON?.parse(val?.meta)?.title}</h5>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-end editor-link-second-half">
                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2" data-id={val?.id} onClick={(e) => editModal(e, val)}>
                                                        <img src={gearPageIcon} width="14px" alt="" data-id={val?.id} onClick={(e) => editModal(e, val)} />
                                                    </button>
                                                    <button className="btn btn-sm link-action-button product-link-action-button ms-2">
                                                        <img src={trashIcon} onClick={() => onDelete(val?.id)} width="14px" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div className="editor-header d-flex align-items-center justify-content-start mt-20px">
                        <button className="bg-blue w-100" onClick={() => showProductsSectionModal('add_block')}>
                            <img src={plusIcon} alt="" width={10} />
                            <span>Add Section</span>
                        </button>
                    </div>
                </div>
            </Tab>
            <Tab
                eventKey="option"
                className="up-in-toggle"
                title={"Options"}
            >
                <CheckoutPage data={data} count={count} handleSubmit={handleSubmit} error={error} setError={setError} />
            </Tab>
        </Tabs>
    )
}

export default ProductTabs