const INIT_STATE = {
    title:null,
    short_description:null,
    product_slug:null,
    price_type:"free",
    price:0,
    min_price:0,
    suggested_price:0,
    currency:"usd",
    cover_image:[],
    deleted_cover_image:[],
}
const productDetailReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_DETAIL':
            return action.payload
        case 'RESET_PRODUCT_DETAIL':
            return INIT_STATE
        default:
            return state
    }
}
export default productDetailReducer