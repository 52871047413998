import React, { createContext, useState, useContext } from 'react';
import AlertMessage from "../services/AlertMessage";

// Create a Context for the alert
const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState(null);

    return (
        <AlertContext.Provider value={{ alert, setAlert }}>
            {children}
            <AlertMessage
                show={!!alert} // Ensure show is a boolean
                setShow={setAlert}
                type={alert?.type} // "error", "warning", or "success"
                title={alert?.title}
                description={alert?.description}
                button_text={alert?.button_text}
                button_link={alert?.button_link}
            />
        </AlertContext.Provider>
    );
};
