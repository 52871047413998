import customizeModalProfileIcon from "../../../assets/img/modals/customize-modal-profile-icon.svg"
import customizeModalThemeIcon2 from "../../../assets/img/modals/theme-icon.svg"
import customizeModalSocialIcon from "../../../assets/img/modals/customize-modal-social-icon.svg"
import customizeModalSeoIcon from "../../../assets/img/modals/customize-modal-seo-icon.svg"
import contentIcon from "../../../assets/img/modals/content-icon.svg"
import checkoutIcon from "../../../assets/img/modals/checkout-icon.svg"
import closeIcon from "../../../assets/img/modals/close-icon.svg"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Modal } from "react-bootstrap"
import { hideLoader, showLoader } from "../../loader"
import { useDispatch, useSelector } from "react-redux"
import ModalErrors from "../../errors/modal-errors"
import { toast } from "react-toastify"
import { apiUrl, appUrl, pageUrl, protocol } from "../../../utils/url"
import DetailsPage from "./product-tabs/details-page"
import ThemePage from "./product-tabs/theme-page"
import ContentPage from "./product-tabs/content-page"
import CheckoutPage from "./product-tabs/checkout-page"
import SectionPage from "./product-tabs/sections"
import AlertMessage from "../../../services/AlertMessage"

export const CustomizeProductModal = ({ show, setShow,data, setIframeURL, iframeIndex, setIframeIndex, editProduct, product_id, edit, setShowSectionChildModal }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [section, setSection] = useState(show?.from == 'add_block' ? 'add_block' : 'details');
    const pro = useSelector(state => state?.proReducer);
    const { basePath, slug } = useSelector(state => state?.slugReducer);
    const [alert, setAlert] = useState(null);

    const [error, setError] = useState([]);
    const handleClose = () => {
        setShow({show:false, from:null})
        // history.push(basePath + "/editProduct")
    }
    const handleSubmit = (e,values,tab) => {
        e.preventDefault();
        const api = apiUrl + "/ProductFlow/update-setting";
        showLoader()
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);
            const formdata = new FormData();
            for (const key in values) {
                if (Object.hasOwnProperty.call(values, key)) {
                    formdata.append(key, values[key]);
                }
            }
            if (tab == "details") {
                if(values?.cover_image){
                    formdata.delete('cover_image');
                    values?.cover_image.map((val, key) => {
                        if (val?.img) {
                          formdata.append("cover_image[]", new File([val.img], "image.png"));
                        }
                    });
                }
            }
            formdata.append("project", slug);
            formdata.append("tab", tab);
            formdata.append("product_id", product_id);
            const setOptions = {
                method: "POST",
                body: formdata,
                headers: myHeaders,
            }
            fetch(api, setOptions)
            .then(async (res) => {
                const response = await res?.json();
                if (response?.status_code == 200) {
                    setTimeout(() => {
                        editProduct(product_id)
                        setIframeURL(protocol + '' + pageUrl + '/' + slug + '/' + data?.slug  + '?iframe=1');
                        setIframeIndex(iframeIndex + 1);
                        hideLoader();
                    }, 1000);
                    setAlert({ type: 'success', title: `Product Updated` });
                    // toast.success(response?.status_message)
                }else{
                    setError(Object.values(response?.errors));
                    if (Object.keys(response?.errors).length > 0)
                        $(".modal-body").scrollTop(0);
                    hideLoader();
                }
            hideLoader();
          }).catch(() => {
            hideLoader();
          });
    };




    return (
        <Modal
            show={show?.show} onHide={handleClose} size={"lg"} id="customizePageModal">
            <div class="modal-dialog-centered modal-xlarge" role="document">
                <div class="modal-content p-0">

                    <div class="modal-body p-0">

                        <div class="customize-page-desktop custom-u" >
                            <button class="btn close-btn" data-bs-dismiss="modal" onClick={handleClose}>
                                <img src={closeIcon} width="15px" alt="" />
                            </button>
                            <div className="fade-u"></div>

                            <div class="left-bar nav nav-tabs" role="tablist">
                                {show?.from == 'add_block' ?
                                <div
                                    class={`customize-page-menu-link custom-width-u customizePageProfile ${section ? section == "add_block" ? "active" : "" : "active"}`}
                                    id="btnPageProfile"
                                    onClick={() => setSection("add_block")}
                                    data-bs-toggle="tab"
                                    data-bs-target="#customizePageProfile"
                                    role="tab" aria-controls="profile"
                                    aria-selected="true">
                                    <img src={customizeModalProfileIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Sections</h6>
                                </div>
                                :
                                <>
                                <div
                                    class={`customize-page-menu-link custom-width-u customizePageProfile ${section ? section == "details" ? "active" : "" : "active"}`}
                                    id="btnPageProfile"
                                    onClick={() => setSection("details")}
                                    data-bs-toggle="tab"
                                    data-bs-target="#customizePageProfile"
                                    role="tab" aria-controls="profile"
                                    aria-selected="true">
                                    <img src={customizeModalProfileIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Details</h6>
                                </div>

                                <div
                                    class={`customize-page-menu-link custom-width-u customizePageTheme ${section == "theme" ? "active" : ""}`}
                                    data-bs-toggle="tab"
                                    id="btnPageTheme"
                                    data-bs-target="#customizePageTheme"
                                    onClick={() => setSection("theme")}
                                    // onClick={() => setSection("pageTheme")}
                                    role="tab" aria-controls="theme"
                                    aria-selected="true"
                                >
                                    <img src={customizeModalThemeIcon2} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Theme</h6>
                                </div>

                                <div
                                    class={`customize-page-menu-link custom-width-u customizePageIntegration ${section == "content" ? "active" : ""}`}
                                    data-bs-toggle="tab" data-bs-target="#customizePageFonts"
                                    id="btnPageFont"
                                   onClick={() => setSection("content")}
                                    role="tab" aria-controls="font" aria-selected="true">
                                    <img src={contentIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Content/Files</h6>
                                </div>

                                <div
                                    class={`customize-page-menu-link customizePageSocials ${section == "checkout" ? "active" : ""}`}
                                    id="btnPageSocials"
                                    onClick={() => setSection("checkout")}
                                    data-bs-toggle="tab" data-bs-target="#customizePageSocials"
                                    role="tab" aria-controls="social" aria-selected="true">
                                    <img src={checkoutIcon} width="32px" alt="" />
                                    <h6 class="pt-1 mb-0 ms-2">Checkout</h6>
                                </div>
                                </>
                            }
                            </div>
                            {section == 'details' ?
                                <DetailsPage data={data} handleSubmit={handleSubmit} error={error} setError={setError} />
                                :
                                section == 'theme' ? 
                                <ThemePage data={data} handleSubmit={handleSubmit} error={error} setError={setError} />
                                :
                                section == 'content' ? 
                                <ContentPage data={data} handleSubmit={handleSubmit} error={error} setError={setError} />
                                :
                                section == 'checkout' ? 
                                <CheckoutPage data={data} handleSubmit={handleSubmit} error={error} setError={setError} />
                                :
                                section == 'add_block' ? 
                                <SectionPage data={data} handleSubmit={handleSubmit} edit={edit} product_id={product_id} setShowSectionChildModal={setShowSectionChildModal} setShow={setShow} />
                                :
                                null
                            }
                        </div>
                    </div>

                </div>
            </div>
            {alert && <AlertMessage
show={alert}
setShow={setAlert}
                type={alert?.type} // "error", "warning"
                title={alert?.title}
                description={alert?.description}
            />}
        </Modal>
    )
}