import React, { useEffect, useState } from 'react'
import payout_settings_paypal from "../../assets/img/payout-settings-paypal.svg"
import payout_settings_stripe from "../../assets/img/payout-settings-stripe.svg"
import upgradeSubmit, { disconnectPayout } from '../upgrade/upgrade-button'
import { useSelector } from 'react-redux'
import { apiUrl } from '../../utils/url'
import DisonnectModal from '../disconnect-modal'
import Teammoadal from '../teammember/team_modal'
import { hideLoader, showLoader } from '../loader'
import { toast } from 'react-toastify'
import { useAlert } from '../../contexts/alertContext'
const PayoutPage = ({setActivePage}) => {
    const { slug } = useSelector(state => state?.slugReducer);
    const { stripeConnectReducer } = useSelector((state) => state);
    const [payoutDisconnectModal, setpayoutDisconnectModal] = useState(false);
    const [showModalasd, setShowModalasd] = useState(false)
    const pro = useSelector(state => state?.proReducer);
    const newUser = useSelector(state => state?.newUserReducer);
    const planType = useSelector(state => state?.plantypeReducer);
    const { setAlert } = useAlert();

    useEffect(() => {
        if (!pro) {
            setShowModalasd(true);
        }else {
            setShowModalasd(false);
        }
    }, [pro,planType]);

    const connectStripe = (e) => {
        if(!showModalasd){
        upgradeSubmit(e, null, slug, "connect", "stripe")
        }
    }
    const disconnectSripe = (e) => {
        e.preventDefault();
        showLoader()
    
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        const formdata = new FormData()
        formdata.append("project", slug ? slug : localStorage?.getItem("slug"));
        
        const api = apiUrl + `/stripe-disconnect`;
        const setOptions = {
            method: "POST",
            body: formdata,
            headers: myHeaders
        }
        fetch(api, setOptions)
        .then(async (res) => {
          const response = await res?.json();
          if (response?.status == 'success') {
            setAlert({ type: 'success', title: 'Payout Method Stripe Removed' }) 
            // toast.success(response?.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
          } else {
            setAlert({ type: 'error', title: response?.errors }) 
            // toast.error(response?.errors);
            hideLoader();
          }
        })
        .catch(() => {
          hideLoader();
        });
    
    
    }
    const connectPayPal = (e) => {
        upgradeSubmit(e, null, slug, "connect", "paypal")
    }
    const disconnectModal = () => {
        setpayoutDisconnectModal(true);
    }
    const onDisconnect = (e) => {
        e.preventDefault();
        disconnectPayout(e, slug, setpayoutDisconnectModal,setAlert);
    }
  return (
    <section className="container h-100 position-relative">
            <div className='container settings-section' style={{padding:"30px"}}>
            <div class="">
                <h1 class="settings-section-heading" style={{marginBottom:"20px"}}>Payout Settings</h1>
            </div>
            <div class="">
                <div className='d-flex justify-content-between align-items-center' style={{background:"#EEEDEE",borderRadius:"12px",padding:'12px',marginBottom:"10px"}}>
                    <div class="form-group d-flex justify-content-start align-items-center w-100">
                        <img src={payout_settings_stripe} width="30px" alt="" />
                        <p className="text-gray font-weight-500 mb-0" style={{marginLeft:"10px"}}>Stripe</p>    
                    </div>
                    {stripeConnectReducer?.stripe == 0 ? 
                        <button type="button" className="payout-buttons" onClick={connectStripe}>Connect</button> : 
                        <button type="button" className="payout-buttons" onClick={disconnectSripe}>Disconnect</button>
                    }
                    </div>
                <div className='d-flex justify-content-between align-items-center' style={{background:"#EEEDEE",borderRadius:"12px",padding:'12px'}}>
                    <div class="form-group d-flex justify-content-start align-items-center w-100">
                        <img src={payout_settings_paypal} width="30px" alt="" />
                        <p className="text-gray font-weight-500 mb-0" style={{marginLeft:"10px"}}>Paypal</p>    
                    </div>
                    {stripeConnectReducer?.paypal == 0 ? 
                        <button type="button" className="payout-buttons" onClick={connectPayPal}>Connect</button>:
                        <button type="button" className="payout-buttons" onClick={disconnectModal}>Disconnect</button>
                    }
                    </div>
            </div>
            </div>
            {payoutDisconnectModal && <DisonnectModal show={payoutDisconnectModal} setShow={setpayoutDisconnectModal} disconnect={onDisconnect} />}
            {showModalasd && <Teammoadal from={"payout-settings"} showModal={showModalasd} setShowModal={setShowModalasd} setActivePage={setActivePage} text={'Payouts is a Pro feature.<br />Upgrade to Zaap Pro to unlock this feature'} isUltra={false}/>}
            </section>
  )
}

export default PayoutPage
