import { useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import import_icon from "../../assets/img/import-modal-icon.svg"
import imageIcon from "../../assets/img/image-icon.svg";
import { createFormdataValues } from "../../utils/createFormdataValues";
import { apiUrl } from "../../utils/url";
import ErrorMessage from "../errors/errorMessage";
import { hideLoader, showLoader } from "../loader";
import returnIcon from "../../assets/img/return.svg";
import { useAlert } from "../../contexts/alertContext";


const ImportModal = ({ show,tags, handleHide, setshowImportModal, callAudience }) => {
    const refUpload = useRef(null);
    const uploadBtnRef = useRef(null);

    const [uploadedFile, setUploadedFile] = useState(null);
    const [importType, setImportType] = useState("csv");
    const [mappingFieldModal, setmappingFieldModal] = useState(false);
    const [subscribersName, setsubscribersName] = useState([])
    const [subscribersEmail, setsubscribersEmail] = useState([]);
    const { slug } = useSelector(state => state?.slugReducer);
    const [error, setError] = useState([])
    const [checkedTags, setCheckedTags] = useState([]);
    const { setAlert } = useAlert();
    const uploadCSV = (e) => {
        e.preventDefault()
        mappingFile(uploadedFile)
    }
    const [formValues, setformValues] = useState({
        subscriber_name_index: "0", subscriber_email_index: "0", project: slug, csv_file: null
    })

    const mappingFile = (files) => {
        showLoader();
        setError([])
        if (files?.length > 0) {
            
// console.log("Map Function", files.length)
            var file = files[0];
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (event) {
                var csv = event.target.result;
                var data = csv.split("\n");
                const csv_cols = data[0].split(",");
                setsubscribersName(csv_cols);
                setsubscribersEmail(csv_cols)
                setformValues({
                    ...formValues, csv_file: files[0],

                })
            }

            handleHide();
            setTimeout(() => {
                hideLoader()
                setmappingFieldModal(true)
            }, 300);
        }
        else {
            
// console.log("Else Working")
            toast.error("Please upload a csv file")
            hideLoader()
        }
    }

    const handleChange = (e) => {
        setUploadedFile(e.target.files)
    };

    const handleCloseMapModal = () => {
        setUploadedFile(null)
        setmappingFieldModal(false);
    };

    const handleChangeSelect = (e) => setformValues({ ...formValues, [e.target.name]: e.target.value });

    const handleSubmit = (e,from = null) => {
        e.preventDefault();
        showLoader()
        const api = apiUrl + "/community/subscribers/import";
        
// console.log("formValues", formValues);
        const formData = createFormdataValues(formValues);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`)
        if (from == 'manually') {
            formData.append("from",from)
            formData.append("project",slug)
        }
        formData.append("tags",JSON.stringify(checkedTags))
        const setOptions = {
            headers: myHeaders,
            body: formData,
            method: 'POST'
        }

        const errorList = []
        fetch(api, setOptions).then(async (res) => {
            const response = await res.json();
            if (response?.status_code == 200) {
                setAlert({ type: 'success', title: response?.status_message }) 
                // toast?.success(response?.status_message);
                if(from == 'manual'){
                    handleHide();
                }
                hideLoader();
                setmappingFieldModal(false);
                callAudience();
            } else {
                if (response?.errors?.csv_file?.length > 0){
                    errorList.push(response?.errors?.csv_file);
                }else{
                    errorList.push(Object.values(response?.errors));
                }

                setError(errorList);
                setAlert({ type: 'error', title: response?.status_message })
                // toast?.error(response?.status_message)
                hideLoader();
            }
        }).catch((error) => {
            setAlert({ type: 'error', title: error })
            // toast?.error(error)
            hideLoader()

        })

    }
    const dropzoneWrapperClick = () => {
        refUpload.current?.click();
    }
    const dropzoneWrapperDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setUploadedFile(null)
        // if (e.dataTransfer.files[0].size > 5242880)
        //     toast.error("File Size Should not exceed 5MBs");
        // else if (e.dataTransfer.files[0].type !== "text/csv")
        if (e.dataTransfer.files[0].type !== "text/csv")
            toast.error("Please select a CSV file");
        else setUploadedFile(e.dataTransfer.files)


    }
    const dropzoneWrapperDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $(e.target).addClass('dragover');
    }

    const dropzoneWrapperLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        $(e.target).removeClass('dragover');
    }
    const handleClose = () => {
        setUploadedFile(null)
        handleHide()
    }
    const toggleImportType = (type) => {
        setImportType(type);
    };
    const handleManuallyChange = (e) => {
        setformValues({ ...formValues, [e.target.name]:e.target.value});
    };
    
const handleTagChange = (e, val) => {
    e.stopPropagation();
    const isChecked = e.target.checked;

    // Get the existing checked tags for the current audience member
    const existingCheckedTags = checkedTags || [];

    // Create a new array with the updated checked tags
    const updatedCheckedTags = isChecked
        ? [...existingCheckedTags, val]
        : existingCheckedTags.filter(tag => tag.tag !== val.tag);

    setCheckedTags(updatedCheckedTags); // Directly set the state with the updated array
};
    return (
        <>
            <Modal centered show={show} onHide={handleClose} className="import-modal">
                <div class="modal-content pt-0 pl-0 pr-0 pb-0">


                    {/* <div class="modal-header create-biolink-header import-subscribers-header"></div> */}

                    <div class="modal-body">
                        <div class="form-group">
                            <img src={import_icon} width="40px" alt="" />
                            <h4 class="mt-2 mb-1">Import Subscribers</h4>
                            <p class="text-gray font-weight-500">Import your subscribers to Zaap. Upload a CSV.</p>
                        </div>
                        <div className="form-group w-100 p-0 mt-3">
                            <label className="productlabels">Import</label>
                            <div className='choose-plan-type'>
                                <div class="up-in-toggle bg-f1f1f1 w-50 btn-semi-rounded mb-2" style={{ padding: "5px 3px" }}>
                                    <input type="radio" id="text_align_left" name="scheduleTime" defaultChecked style={{ display: "none" }} onChange={() => toggleImportType("csv")} value={"csv"} />
                                    <label for="text_align_left" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded " >Import CSV</label>
                                    <input type="radio" id="text_align_right" name="scheduleTime"  style={{ display: "none" }} value={"manual"}  onChange={() => toggleImportType("manual")}/>
                                    <label for="text_align_right" class="btn-semi-rounded toggletext newsletter-btn-semi-rounded "  > Enter Manually</label>
                                </div>
                            </div>
                        </div>
                        <ErrorMessage error={error} />
                        <form action="" id="" method="post" role="form" onSubmit={uploadCSV}>
                            <input type="hidden" name="token" value="792de2bf431c51332d993040ae70217e" />
                            {importType == 'csv' ? 
                            <>
                            {!uploadedFile ?
                                <div class="dropzone-wrapper dropzone-wrapper4 " onClick={dropzoneWrapperClick} onDragOver={dropzoneWrapperDrag} onDragLeave={dropzoneWrapperLeave} onDrop={dropzoneWrapperDrop}>
                                    
                                    <div className="dropzone-desc">
                                        <img src={imageIcon} alt="" />
                                        <h6 className="mt-2 mb-1 text-gray">Drag and drop files, or <a className="text-primary decoration-none">click here to browse</a></h6>
                                        <small className="text-muted-light">Upload a CSV with your audience</small>
                                    </div>
                                    <input type="file" class="d-none dropzone" name="csv_file" ref={refUpload} accept=".csv" onChange={handleChange} />
                                </div>
                                :
                                <small>{uploadedFile[0]?.name}</small>
                            }</>:
                            <>
                                <div className="d-flex flex-column gap-2 ">
                                    <input
                                        type="text"
                                        className="zaapGlobal-input"
                                        onChange={(e)=>handleManuallyChange(e)}
                                        name="name"
                                        placeholder="Subscriber Name"
                                        value={formValues?.name}
                                    />
                                    <input
                                        type="email"
                                        className="zaapGlobal-input"
                                        onChange={(e)=>handleManuallyChange(e)}
                                        name="email"
                                        placeholder="Subscriber Email"
                                        value={formValues?.email}
                                    />
                                </div>
                            </>
                            }
                            <div className="bg-grey mt-2">
                                <div className="ms-1 add-tags-dropdown add-tags-dropdown-bulk dropdown-toggle noselect border-0" data-bs-toggle="dropdown" aria-expanded="false" onClick={(e) => e.stopPropagation()} id="bulk-add-tags-dropdown">
                                    <span className="bulk-tag-text d-flex align-items-center gap-1 cursor-pointer">
                                    {checkedTags?.length > 0 ? (
                                        'Edit Tags'
                                        ) : (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 13" fill="none">
                                            <path d="M6.64084 1.64085C6.64084 1.14885 6.242 0.75 5.75 0.75C5.258 0.75 4.85915 1.14884 4.85915 1.64085V11.3592C4.85915 11.8512 5.258 12.25 5.75 12.25C6.242 12.25 6.64084 11.8512 6.64084 11.3592V1.64085Z"fill="#333333"></path>
                                            <path d="M0.890844 5.60916C0.398845 5.60916 0 6.008 0 6.5C0 6.992 0.398845 7.39085 0.890844 7.39085L10.6092 7.39085C11.1012 7.39085 11.5 6.992 11.5 6.5C11.5 6.008 11.1012 5.60915 10.6092 5.60915L0.890844 5.60916Z" fill="#333333" ></path>
                                            </svg>
                                            Add Tag
                                        </>
                                        )}:</span>
                                </div>
                                <ul className="audience-tags-dropdown audience-tags-dropdown-bulk dropdown-menu" aria-labelledby="bulk-add-tags-dropdown">
                                    {tags?.map((value, index) => {
                                        const isChecked = checkedTags?.some(tag =>  tag?.tag === value?.tag);
                                        return (
                                            <li key={index} className="dropdown-item tags-option" onClick={(e) => e.stopPropagation()}>
                                            <input
                                                onChange={(e)=>handleTagChange(e,value)}
                                                className="tag-checked "
                                                type="checkbox"
                                                id={`tag-checkbox-${index}`}
                                                style={{ display: 'none' }}
                                                checked={isChecked}
                                            />
                                            <label onClick={(e) => e.stopPropagation()} style={{ backgroundColor: value?.color }} className="small-box" htmlFor={`tag-checkbox-${index}`}>
                                            </label>
                                            <span onClick={() => {
                                                const checkbox = document.getElementById(`tag-checkbox-${index}`);
                                                if (checkbox) {
                                                    checkbox.click(); // Trigger checkbox click event
                                                }
                                            }} className={`cursor-pointer ${isChecked ? 'checked-tag':''}`}>{value?.tag}</span>
                                        </li>)
                                    })} 
                                </ul>
                            </div>
                            <div class="text-center mt-4 d-flex">
                                <button type="button" class="close btn btn-grey btn-semi-rounded me-2 d-flex align-items-center justify-content-center" onClick={handleClose}>
                                    <b class="me-2">Cancel</b>
                                    <small class="badge bg-light text-dark p-1">Esc</small>
                                </button>
                                {importType == 'csv' ?
                                     <button autoFocus type="submit" class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b class="me-2">Upload CSV</b>
                                        <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                    :
                                    <button autoFocus type="button" onClick={(e) => handleSubmit(e,'manually')} class="btn btn-primary btn-semi-rounded d-flex align-items-center justify-content-center">
                                        <b class="me-2">Upload</b>
                                        <small class="badge bg-primary-dark text-dark px-2 py-1 d-flex align-items-center justify-content-center">
                                            <img src={returnIcon} alt="" />
                                        </small>
                                    </button>
                                }
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
            <Modal centered show={mappingFieldModal} onHide={handleCloseMapModal} className="import-modal">
                <div class="modal-content pt-0 pl-0 pr-0 pb-0">
                    <div class="modal-header create-biolink-header">
                        <h5 class="modal-title create-biolink-heading">Map CSV Fields</h5>
                        <button type="button" class="close" onClick={handleCloseMapModal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <form name="import_subscribers_form" id="import_subscribers_form" method="post" role="form" onSubmit={handleSubmit}>
                            <div class="notification-container"></div>
                            <ErrorMessage error={error} />
                            <div class="form-group">
                                <label>Subscriber Name:</label>
                                <select name="subscriber_name_index" class="form-select" id="" required onChange={handleChangeSelect} selected={formValues.subscriber_name_index} >
                                    {subscribersName.length > 0 && (
                                        subscribersName?.map((val, index) => (
                                            <option key={index} value={index}>{val}</option>
                                        ))
                                    )}
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Subscriber Email:</label>
                                <select name="subscriber_email_index" class="form-select" id="" required onChange={handleChangeSelect} selected={formValues.subscriber_email_index} >
                                    {subscribersEmail.length > 0 && (
                                        subscribersEmail?.map((val, index) => (
                                            <option key={index} value={index}>{val}</option>
                                        ))
                                    )}

                                </select>
                            </div>
                            <div class="text-center mt-4 d-flex justify-content-between" >
                                <button type="button" onClick={handleCloseMapModal} class="btn btn-grey btn-rounded mr-2 py-3">
                                    Cancel</button>
                                <button type="submit" name="submit" class="btn btn-primary btn-rounded py-3">
                                    Continue
                                    <i class="fas fa-chevron-right  mx-2"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ImportModal