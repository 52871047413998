const INIT_STATE = {
    content:null,
    duration:null,
    custom_meet_link:null,
    timezone:null,
    meeting_location:null,
    timezone:null,
    content_file_name:null,
    content_file:null,
    content_url:null,
    product_content_data:[{}],
}
const productContentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_CONTENT':
            return action.payload
        case 'RESET_PRODUCT_CONTENT':
            return INIT_STATE
        default:
            return state
    }
}
export default productContentReducer